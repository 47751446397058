<mat-chip-listbox
  attr.aria-label="{{ aria }} list"
  required
  [hideSingleSelectionIndicator]="true"
>
  @for (item of items; track $index) {
    <mat-chip-option
      [selected]="item.selected"
      class="custom rounded"
      [ngClass]="{ selected: item.selected }"
      [value]="item.value"
      [selectable]="!multiSelect && !item.selected"
      (click)="changesList(item.value)"
      >{{ item.value }}
      @if (item.counter !== 0) {
        <dmc-ng-badge
          [width]="16"
          [value]="item.counter.toString()"
          [border]="item.selected ? 'white' : 'primary'"
          [color]="item.selected ? 'secondary' : 'primary'"
        ></dmc-ng-badge>
      }
    </mat-chip-option>
  }
</mat-chip-listbox>

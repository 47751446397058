import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatChipListboxChange, MatChipsModule } from '@angular/material/chips';
import { BadgeComponent } from '@dmc-ng/ui/badge';

import { ChipsModel } from './model/chips.model';

@Component({
  selector: 'dmc-ng-chips-list',
  standalone: true,
  imports: [CommonModule, MatChipsModule, BadgeComponent],
  templateUrl: './chips-list.component.html',
  styleUrl: './chips-list.component.scss',
})
export class ChipsListComponent {
  @Input() aria = 'chips';
  @Input() items!: ChipsModel[];
  @Input() multiSelect = false;
  @Output() selected = new EventEmitter<string>();

  changesList(chip: string): void {
    if (!this.multiSelect) {
      this.selected.emit(chip);
    }
  }
}
